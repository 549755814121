

















































 import { Page, Sort, Filter, Edit, Toolbar,CommandColumn  } from "@syncfusion/ej2-vue-grids";
import { Component, Vue } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import {MyGridOption} from '@/Helper/MyDataAdapter';
import { WebApi } from "@/Helper/WebApi";
import { StudentCourseTrack, Content } from '../../Models/StudentCourseTrack';
Vue.use(GridPlugin);
@Component({
     components: {
  },
  provide:{
     grid: [Page, Sort, Filter, Edit, Toolbar,CommandColumn ]
  }
})
export default class Student extends Vue {
  dataManager = MyGridOption.getDataManager('TeacherStudentList');
  filterOptions = {    type: 'Excel'};
  wrapSettings= { wrapMode: 'Both ' };
  TrackModel:any=null;
  ShowTrack:boolean=false;
  commands= [{ buttonOption: { content: 'Track', cssClass: 'e-flat' } }]
   commandClick(args:any) {
    WebApi.GetStudentCourseStatus(args.rowData.Id).then(d=>{
      var data:StudentCourseTrack = d;
      this.TrackModel = data;
      this.ShowTrack = true;
      this.$store.commit("ChangeLoading", false);

    });
    
}
getTrackStatus(count:number){
  if(count==0)
  return "Pending";
  else
  return "Done";
    }
    HideDialog(){
      this.ShowTrack = false;
    }
}
